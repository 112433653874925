export const dark = {
  colors: {
    color: "#c0c0c0",
    bgColor: "#252627",
  },
};

export const light = {
  colors: {
    color: "#2f2f2f",
    bgColor: "#ffffff",
  },
};
